<template>
  <section class="mb-60">
    <div class="mb-60">
      <div v-for="(cart_shipping, cart_shipping_index) in userCartData.cart_shippings" :key="`shipping-${cart_shipping_index}`"
           class="order-wrapper mb-20">
        <div class="order-item_wrapper">
          <table class="common-table order-item">

            <template v-for="(cart_category, cart_category_index) in cart_shipping.cart_categories">

              <tr v-for="(cart_product, cart_product_index) in cart_category.cart_products"
                  :key="`category-${cart_category_index}-product-${cart_product_index}`">
                <th>{{ cart_product_index + 1 }}</th>

                <template v-if="cart_category.category_type == 'normal'">
                  <td colspan="4">
                    <div class="order-item__detail">
                      <div class="order-item__thumb__wrapper">
                        <div class="order-item__thumb">
                          <img :src="cart_product.image_url" alt="">
                        </div>
                        <div v-if="cart_product.sub_commodity" class="order-item__thumb">
                          <img :src="cart_product.sub_commodity.sample_image_url[0]" alt="">
                        </div>
                      </div>
                      <div class="order-item__main">
                        <p class="order-item__name">{{ cart_product.name }}</p>
                        <p class="order-item__id">ID: {{ cart_product.sale_product_id }}</p>
                        <ul class="order-item__detail">
                          <li>
                            ¥{{ cart_product.unit_price }}
                            <span class="small c-darkgray no-word-break"> {{ $t('purchase_confirmation.tax_included') }}</span>
                          </li>
                          <li>
                            <span class="small c-darkgray">{{ $t('purchase_confirmation.quantity') }}: </span>
                            {{ cart_product.qty }}
                          </li>
                          <li class="pr-0">
                            <span class="small c-darkgray">{{ $t('purchase_confirmation.subtotal') }}: </span>
                            ¥{{ cart_product.subtotal }}
                            <span class="small c-darkgray no-word-break"> {{ $t('purchase_confirmation.tax_included') }}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </td>

                  <td v-if="!cart_product_index" :rowspan="cart_category.cart_products.length" class="order-item__quantity" colspan="2">
                    <ol>
                      <li class="pr-0">
                        ¥{{ cart_category.cart_products[0].unit_price }}
                        <span class="small c-darkgray no-word-break"> {{ $t('purchase_confirmation.tax_included') }}</span><br>
                      </li>
                      <li class="pr-0">
                        <span class="small c-darkgray">{{ $t('purchase_confirmation.quantity') }}: </span>
                        {{ cart_category.qty }}
                      </li>
                      <li class="pr-0">
                        <span class="small c-darkgray">{{ $t('purchase_confirmation.subtotal') }}: </span>
                        ¥{{ cart_category.subtotal }}
                        <span class="small c-darkgray no-word-break"> {{ $t('purchase_confirmation.tax_included') }}</span>
                      </li>
                    </ol>
                  </td>
                </template>

                <template v-else>
                  <td colspan="4">
                    <div class="order-item__detail">
                      <div class="order-item__thumb__wrapper">
                        <div class="order-item__thumb">
                          <img :src="cart_product.image_url" alt="">
                        </div>
                        <div v-if="cart_product.sub_commodity" class="order-item__thumb">
                          <img :src="cart_product.sub_commodity.sample_image_url[0]" alt="">
                        </div>
                      </div>
                      <div class="order-item__main">
                        <p class="order-item__name">{{ cart_product.name }}</p>
                        <p class="order-item__id">ID: {{ cart_product.sale_product_id }}</p>
                        <ul class="order-item__detail">
                          <li>
                            <span class="small c-darkgray">{{$t('purchase_confirmation.quantity')}}: </span>
                              {{ cart_product.qty }}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </td>

                  <td v-if="!cart_product_index" :rowspan="cart_category.cart_products.length" class="order-item__quantity" colspan="2">
                    <ol>
                      <li v-if="cart_category.print_sale_plan_type !== 'bundled'" class="pr-0">
                        ¥{{ cart_category.cart_products[0].unit_price }}
                        <span class="small c-darkgray no-word-break"> {{ $t('purchase_confirmation.tax_included') }}</span><br>
                      </li>
                      <li v-if="cart_category.print_sale_plan_type === 'bundled'" class="pr-0">
                        <span class="small c-darkgray">{{ $t('purchase_confirmation.quantity') }}: </span>
                        <br class="s-elem"/>
                        {{ cart_category.qty / cart_category.category_printed_product_set_amount }}セット
                      </li>
                      <li v-else class="pr-0">
                        <span class="small c-darkgray">{{ $t('purchase_confirmation.quantity') }}: </span>
                        {{ cart_category.qty }}
                      </li>
                      <li class="pr-0">
                        <span class="small c-darkgray">{{ $t('purchase_confirmation.subtotal') }}: </span>
                        ¥{{ cart_category.subtotal }}
                        <span class="small c-darkgray no-word-break"> {{ $t('purchase_confirmation.tax_included') }}</span>
                      </li>
                      <li>
                        <ValidationProvider
                          v-slot="{ errors }"
                          class="input"
                          rules="is:0"
                        >
                          <input v-model="cart_category.is_invalid_print_set" type="hidden">
                          <span v-if="errors[0]" class="validate-error c-red">{{
                              $t('cart.invalide_qty_in_printed_sets', {qty_per_set: cart_category.bundled_unit})
                            }}</span>
                        </ValidationProvider>
                      </li>
                    </ol>
                  </td>
                </template>

              </tr>
            </template>
          </table>
        </div>

        <div class="order-menu_wrapper">
          <table class="order-menu">
            <tr style="height:58px">
              <th class="estimated_delivery__header">{{ $t('purchase_confirmation.estimated_delivery') }}</th>
              <th class="shipping_method_selection_charge__header">{{ $t('purchase_confirmation.shipping_method_selection_charge') }}</th>
            </tr>
            <tr>
              <td>{{ $t('purchase_confirmation.after_payment_is_completed') }}
                <br class="nb_days_of_order_until_shipping__br">
                {{ cart_shipping.product_standards.nb_days_of_order_until_shipping }}
                {{ $t(`purchase_confirmation.${cart_shipping.product_standards.unit_of_day}`) }}
              </td>
              <td>

                <template v-if="!isEnableShippingMethod">
                  <template
                    v-for="(express_shipping, express_shipping_index) in cart_shipping.product_standards.express_shippings">
                    <label v-if="cart_shipping.shipping_method === express_shipping.name" :key="`label-express-shipping-${express_shipping_index}`"
                           class="mb-10">
                      <span>{{ $t(`purchase_confirmation.${express_shipping.name}`) }}</span>
                      <br>
                      <span>¥{{ calcDeliveryAmount(express_shipping) }}&nbsp;{{ $t('purchase_confirmation.tax_included') }}</span>
                      <br>
                      <span class="shipping_description">※お届け目安:1~3日</span>
                      <br>
                      <span class="shipping_description">※直接手渡し</span><br>
                      <template v-if="cart_shipping.specific_time_delivery">
                        {{ labels.specific_time_delivery[cart_shipping.specific_time_delivery] }}
                      </template>
                    </label>
                  </template>

                  <template
                    v-for="(mail_shipping, mail_shipping_index) in cart_shipping.product_standards.mail_shippings">
                    <label v-if="cart_shipping.shipping_method == mail_shipping.name" :key="`label-mail-shipping-${mail_shipping_index}`"
                           class="mb-10">
                      <span style="letter-spacing: -0.5px">{{ $t(`purchase_confirmation.${mail_shipping.name}`) }}</span>
                      <br>
                      <span>¥{{ mail_shipping.delivery_classes[0].amount }}&nbsp;{{ $t('purchase_confirmation.tax_included') }}</span>
                      <br>
                      <template v-if="mail_shipping.name === 'yu_mail'">
                        <span class="shipping_description">※お届け目安:3~6日</span>
                        <br>
                        <span class="shipping_description">※ポスト投函</span>
                        <br>
                        <span class="shipping_description"><span>※土日祝日は配達業務を行なって</span>
                        <br class="s-elem"/>
                        <span class="shipping_description__paragraph">おりません。</span></span>
                      </template>
                      <template v-if="mail_shipping.name === 'nekopos'">
                        <span class="shipping_description">※お届け目安:1~3日</span>
                        <br>
                        <span class="shipping_description">※ポスト投函</span>
                      </template>
                    </label>
                  </template>

                </template>

                <ValidationProvider
                  v-else
                  v-slot="{ errors }"
                  :name="$t('purchase_confirmation.shipping_method_selection_charge')"
                  :vid="cart_shipping_index+''"
                  class="input"
                  rules="required"
                >
                  <div v-if="!cart_shipping.product_standards.mail_shippings.length && !cart_shipping.product_standards.express_shippings.length">
                    {{ $t('purchase_confirmation.selectable_shipping_method_is_nothing') }}
                  </div>

                  <!-- mail shipping -->
                  <div
                    v-for="(mail_shipping) in cart_shipping.product_standards.mail_shippings"
                    :key="`input-mail-shipping-${cart_shipping_index}-${mail_shipping.name}-key`"
                  >
                    <input
                      type="radio"
                      :id="`input-mail-shipping-${cart_shipping_index}-${mail_shipping.name}`"
                      :key="`input-mail-shipping-${cart_shipping_index}-${mail_shipping.name}`"
                      v-model="cart_shipping.shipping_method"
                      :value="mail_shipping.name"
                      @change="() => onShippingMethodChange(cart_shipping, mail_shipping)"
                    />
                    <label
                      :key="`label-mail-shipping-${cart_shipping_index}-${mail_shipping.name}`"
                      :for="`input-mail-shipping-${cart_shipping_index}-${mail_shipping.name}`"
                      class="radio--label mb-10"
                      :style="mail_shipping.name === 'nekopos' ? {'width':'100%','padding': '30px 0 30px 26px'} : {'width':'100%'}"
                    >
                      <span style="letter-spacing: -0.5px">{{ $t(`purchase_confirmation.${mail_shipping.name}`) }}</span>
                      <br>
                      <span>¥{{mail_shipping.delivery_classes[0].amount }}&nbsp;{{ $t('purchase_confirmation.tax_included') }}</span>
                      <br>
                      <template v-if="mail_shipping.name === 'yu_mail'">
                        <span class="shipping_description">※お届け目安:3~6日</span>
                        <br>
                        <span class="shipping_description">※ポスト投函</span>
                        <br>
                        <span class="shipping_description"><span>※土日祝日は配達業務を行なって</span>
                        <br class="s-elem"/>
                        <span class="shipping_description__paragraph">おりません。</span></span>
                      </template>
                      <template v-if="mail_shipping.name === 'nekopos'">
                        <span class="shipping_description">※お届け目安:1~3日</span>
                        <br>
                        <span class="shipping_description">※ポスト投函</span>
                      </template>
                    </label>
                  </div>

                  <!-- express delivery -->
                  <div
                    v-for="(express_shipping, express_shipping_index) in cart_shipping.product_standards.express_shippings"
                    :key="`input-express-shipping-${cart_shipping_index}-${express_shipping.name}-key`"
                  >
                    <input
                      type="radio"
                      :id="`input-express-shipping-${cart_shipping_index}-${express_shipping.name}`"
                      :key="`input-express-shipping-${cart_shipping_index}-${express_shipping.name}`"
                      v-model="cart_shipping.shipping_method"
                      :value="express_shipping.name"
                      @change="() => onShippingMethodChange(cart_shipping, express_shipping)"
                      @click="revalidate"
                    >

                    <label
                      :key="`label-express-shipping-${cart_shipping_index}-${express_shipping.name}`"
                      :for="`input-express-shipping-${cart_shipping_index}-${express_shipping.name}`"
                      class="radio--label mb-10"
                    >
                      <span>{{ $t(`purchase_confirmation.${express_shipping.name}`) }}</span>
                      <br>
                      <span>¥{{ calcDeliveryAmount(express_shipping) }}&nbsp;{{ $t('purchase_confirmation.tax_included') }}</span>
                      <br>
                      <span class="shipping_description">※お届け目安:1~3日</span>
                      <br>
                      <span class="shipping_description">※直接手渡し</span>
                    </label>

                    <!-- delivary time options -->
                    <ValidationProvider
                      :key="`express.validator.${express_shipping_index}`"
                      v-slot="{ errors }"
                      :rules="cart_shipping.shipping_method === express_shipping.name ? 'required' : ''"
                      class="input"
                    >
                      <div
                        class="form-select delivery_time_select__wrapper"
                        v-if="express_shipping.delivery_time_options.length"
                      >
                        <select
                          class="delivery_time_select"
                          :disabled="cart_shipping.shipping_method !== express_shipping.name"
                          :name="$t('purchase_confirmation.time')"
                          :value="cart_shipping.shipping_method === express_shipping.name ? cart_shipping.specific_time_delivery : null"
                          @change="(val) => onShippingMethodChange(cart_shipping, express_shipping, val.target.value)"
                        >
                          <option value="" disabled selected style="display:none;">希望の配達時間を選択</option>
                          <option
                            v-for="item in express_shipping.delivery_time_options"
                            :key="`${express_shipping.name}-${item.key}`"
                            :value="item.key"
                          >
                            {{ labels.specific_time_delivery[item.key] }}
                          </option>
                        </select>
                      </div>
                      <div v-else style="font-size: 11px; margin-bottom: 8px;">配達時間の指定はできません</div>

                      <template v-if="cart_shipping.shipping_method === express_shipping.name && errors.length">
                        <br>
                        <span v-if="errors[0]" class="validate-error c-red">{{ errors[0] }}</span>
                      </template>
                    </ValidationProvider>
                  </div>

                  <span v-if="errors[0]" class="validate-error c-red">{{ errors[0] }}</span>
                </ValidationProvider>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <dl class="order-item__total">
        <dt>{{ $t('purchase_confirmation.subtotal') }}</dt>
        <dd>
          ¥{{ calcSubtotalWithTax }}
          <span class="small c-darkgray no-word-break"> {{ $t('purchase_confirmation.tax_included') }}</span>
        </dd>
        <dt>{{ $t('purchase_confirmation.shipping') }}</dt>
        <dd>
          ¥{{ calculateShippingFee }}
          <span class="small c-darkgray no-word-break"> {{ $t('purchase_confirmation.tax_included') }}</span>
        </dd>
        <template v-if="calculatePaymentFee">
          <dt>{{ $t('purchase_confirmation.payment_fee') }}</dt>
          <dd>
            ¥{{ calculatePaymentFee }}
            <span class="small c-darkgray no-word-break"> {{ $t('purchase_confirmation.tax_included') }}</span>
          </dd>
        </template>
        <dt>{{ $t('purchase_confirmation.total_payment_amount') }}</dt>
        <dd>
          ¥{{ calculateGrandtotal }}
          <span class="small c-darkgray no-word-break"> {{ $t('purchase_confirmation.tax_included') }}</span>
        </dd>
      </dl>
      <p v-if="displayQualifiedInvoiceIssuerNumber" class="reorder_qualified_invoice_issuer_number">
        **************************
        <br>
        適格請求書発行事業者
        <br>
        登録番号:{{qualifiedInvoiceIssuerNumber}}
        <br>
        **************************
      </p>
    </div>
    <div class="mb-30">
      <h4 class="main-title">{{ $t('purchase.basic_information') }}</h4>
      <dl class="common-dl">
        <dt>{{ $t('purchase.name') }}</dt>
        <dd>{{ userPurchaseInfo.last_name + ' ' + userPurchaseInfo.first_name }}</dd>
        <dt>{{ $t('purchase.furigana') }}</dt>
        <dd>{{ userPurchaseInfo.last_name_hiragana + ' ' + userPurchaseInfo.first_name_hiragana }}</dd>
        <dt>{{ $t('purchase.mail_address') }}</dt>
        <dd>{{ userPurchaseInfo.email }}</dd>
        <dt>{{ $t('purchase.sex') }}</dt>
        <dd>{{ $t(`purchase.${userPurchaseInfo.gender}`) }}</dd>
        <dt>{{ $t('purchase.profession') }}</dt>
        <dd>{{ $t(`purchase.occupations.${userPurchaseInfo.occupation}`) }}</dd>
        <dt>{{ $t('purchase.date_of_birth') }}</dt>
        <dd>{{ dateOfBirthDisplay }}</dd>
      </dl>
    </div>
    <div class="address-title-container">
      <h4 class="main-title address-title">{{ $t('purchase.product_delivery_address') }}</h4>
      <button v-if="isEnableShippingMethod" class="btn-100 btn-edit-address" @click="$emit('edit-address-click')">
        {{ $t('mypage.product_delivery_address.edit') }}
      </button>
    </div>
    <dl class="common-dl">
      <dt>{{ $t('purchase.postal_code') }}</dt>
      <dd>{{ userPurchaseInfo.zip_code }}</dd>
      <dt>{{ $t('purchase.prefectures') }}</dt>
      <dd>{{ userPurchaseInfo.prefecture }}</dd>
      <dt>{{ $t('purchase.street_address') }}</dt>
      <dd>{{ userPurchaseInfo.address }}<br>{{ userPurchaseInfo.address_2 }}</dd>
      <dt>{{ $t('purchase.phone_number') }}</dt>
      <dd>{{ userPurchaseInfo.phone_number }}</dd>
    </dl>
  </section>
</template>
<style scoped>
.address-title {
  border: none;
  margin-bottom: 0;
}

.address-title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #121314;
  align-items: center;
}

.btn-edit-address {
  position: relative;
  top: -2px;
  margin: 0px 0 !important;
  max-width: 210px;
  padding: 10px 0;
  font-size: 12px !important;
}

@media only screen and (max-width: 600px) {
  .btn-edit-address {
    max-width: 80px;
  }
}

</style>

<script>
import AttSettingService from '@services/AppSettingService';
import Constants from '@constants';
import {getPaymentFee} from '../../constants/payment'
import { mapGetters } from "vuex";

export default {
  props: {
    selected_payment_id: {
      require: true
    },
    errors: {
      type: Object,
      default() {
        return {
          payment_fail: [],
          product_stock: []
        }
      }
    },
    displayQualifiedInvoiceIssuerNumber: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      labels: {
        specific_time_delivery: {
          'until_12': '午前中(12時まで)',
          '8_12': '午前中(8時 - 12時)',
          '12_14': '12:00 - 14:00',
          '14_16': '14:00 - 16:00',
          '16_18': '16:00 - 18:00',
          '18_20': '18:00 - 20:00',
          // フジフイルムのOrder.xmlで使用できないため不採用
          // '18_21' => '18:00 - 21:00',
          '19_21': '19:00 - 21:00',
        },
      },
      tax: 0,
      userCartData: {
        cart_shippings: []
      },
      userPurchaseInfo: {},
      paymentFeeSetting: {}
    }
  },
  created() {
    this.init();
    this.userPurchaseInfo = this.$cookies.get(Constants.USER_REPURCHASE_INFO);
  },
  methods: {
    updateAddress(val) {
      this.userPurchaseInfo = val
    },
    revalidate() {
      this.$emit('re-validate')
    },
    init() {
      // 税率・決済手数料
      AttSettingService.getPaymentFee().then(res => {
        this.tax = res.data.tax;
        this.paymentFeeSetting = res.data.value
      })

      // カートプロダクtp取得
      // PurchaseService.getPurchaseProduct().then(res => {
      //   if (res.data.cart_shippings.length == 0) {
      //     return this.$router.push({name: 'cart.page'})
      //   }
      //   this.userCartData = res.data
      // })
      /**
       * userCartData
       *  total_purchase_amount_without_payment_fee
       *  shipping_fee
       *  subtotal_include_tax
       *  cart_shippings : []
       *    {
       *      no_delivery_selected
       *      over_quantity_products
       *      subtotal
       *      shipping_method
       *      shipping_fee
       *      specific_time_delivery
       *      category_type
       *      product_standards[]
       *      cart_categories[]
       *    }
       * */
      this.userCartData = this.repurchaseUserCartData
    },
    onShippingMethodChange(cart_shipping, delivery, delivery_time_frame_key = null) {
      // ラジオにチェックが入った時
      if (this.isEnableShippingMethod) {
        cart_shipping.delivery_id = delivery.id
        cart_shipping.shipping_method = delivery.name
        cart_shipping.shipping_fee = this.calcDeliveryAmount(delivery)
        cart_shipping.specific_time_delivery = delivery_time_frame_key
      }
    },
  },
  watch: {
    isEventSaleTermExpired(val) {
      if (!val) {
        this.init()
      }
    }
  },
  computed: {
    ...mapGetters([
      'repurchaseData',
      'repurchaseType',
      'repurchaseUserCartData'
    ]),
    isEventSaleTermExpired() {
      return this.$store.state.cart.isEventSaleTermExpired
    },
    isEnableShippingMethod() {
      return this.$route.name === `repurchase.${this.repurchaseType}.confirmation`
    },
    dateOfBirthDisplay() {
      if (!this.userPurchaseInfo || !this.userPurchaseInfo.date_of_birth) {
        return ''
      }

      const [year, , day] = this.userPurchaseInfo.date_of_birth.split('-')

      const month = new Date(this.userPurchaseInfo.date_of_birth).getMonth() + 1

      return `${year}年${month}月${day}日`
    },
    calcDeliveryAmount() {
      return (delivery) => {
        // 現在選択中のprefecture_idからdelivery_classesを特定、amountを返す

        const target_delivery_class = delivery.delivery_classes.find(delivery_class => {
          return delivery_class.prefectures.some(prefecture => prefecture.id === parseInt(this.userPurchaseInfo.prefecture_id))
        })

        if (!target_delivery_class) return null

        return target_delivery_class.amount
      }
    },
    /**
     * 再注文・再配送では即払いでないコンビニ決済は選択できないため、決済手数料は実質0円固定
     *
     * @returns {number}
     */
    calculatePaymentFee() {
      // 商品金額・送料ともに表示金額が税込のため、決済手数料は税抜額で計算
      // コンビニ決済の場合のみ商品合計(税込)＋送料(税込)が5330以上の場合は0に
      const paymentFee = getPaymentFee(
        this.selected_payment_id,
        this.userCartData.total_purchase_amount_without_payment_fee + this.calculateShippingFee,
        this.paymentFeeSetting
      )

      return Math.ceil(paymentFee * (100 + this.tax) / 100);
    },
    calcSubtotalWithTax() {
      if (!this.repurchaseUserCartData || this.repurchaseType === 'reshipping') return 0

      const total = this.repurchaseUserCartData.cart_shippings.reduce( (_total, cart_shipping) => {

        const sub_total = cart_shipping.cart_categories.reduce((_sub_total, cart_product) => {
          return _sub_total + cart_product.subtotal
        }, 0)

        _total += sub_total

        return _total
      }, 0)


      return total
    },
    calculateShippingFee() {
      // 送料は税込前提
      return this.userCartData.cart_shippings.reduce((res, el) => res + el.shipping_fee, 0)
    },
    calculateGrandtotal() {
      // return this.calculatePaymentFee + this.userCartData.subtotal_include_tax + this.calculateShippingFee
      return this.calculatePaymentFee + this.calcSubtotalWithTax + this.calculateShippingFee
    },
    qualifiedInvoiceIssuerNumber() {
      return process.env.VUE_APP_QUALIFIED_INVOICE_ISSUER_NUMBER
    }
  },
}
</script>

<style>
.delivery_time_select {
  padding: 10px 28px 10px 10px !important;
}

.delivery_time_select:disabled {
  background-color: #dddddd;
}

.reorder_qualified_invoice_issuer_number {
  text-align: right;
  margin-top: 12px;
}
</style>
